import React from 'react';

export default function NewTag({ right, top }) {
  return (
    <span style={{
      color: 'white',
      fontSize: '12px',
      fontWeight: 600,
      padding: '1px 8px',
      backgroundColor: 'red',
      borderRadius: '5px',
      letterSpacing: '0.5px',
      position: 'absolute',
      right,
      top,
    }}
    >
      new
    </span>
  );
}
