import MainAgreement from './main-agreement.pdf';
import MasterAgreement from './main-agreement-master.pdf';
import Appendix532 from './appendix-532.pdf';
import AppendixMaster from './appendix-master.pdf';

export default {
  MainAgreement,
  MasterAgreement,
  Appendix532,
  AppendixMaster,
};
