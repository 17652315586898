import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { MdLockOutline } from 'react-icons/md';
import clientPresetOptions from '../../../../utils/clientPresetOptions';
import { userController } from '../../../../controllers';
import '../../../../styles/Dashboard/Widgets.scss';
import { Button } from '../../..';
import Blocker from '../../../Blocker';

function ClientPresets({ locked }) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'));
  const [pending, setPending] = useState(false);
  const affiliateDataFetch = JSON.parse(localStorage.getItem('affiliateData'));
  const [existingClientPresets, setExistingClientPresets] = useState({});
  const [clientPresets, setClientPresets] = useState({
    tradingPlatform: clientPresetOptions.tradingPlatform[0],
    tradingCurrency: clientPresetOptions.tradingCurrency[0],
    campaignId: '',
  });

  useEffect(() => {
    if (user?.clientPresets?.current?.length > 0) {
      setClientPresets(user.clientPresets.current[0]);
      setExistingClientPresets(user.clientPresets.current[0]);
      if (user.clientPresets.current[0].pending) setPending(true);
      else setPending(false);
    } else if (affiliateDataFetch) {
      setClientPresets((prevS) => ({ ...prevS, campaignId: Object.keys(affiliateDataFetch.campaigns)[0] }));
    }
  }, []);

  const submitClientPresets = async (event) => {
    event.preventDefault();
    if (pending) return enqueueSnackbar('You already have a pending request', { variant: 'error' });
    if (existingClientPresets.tradingPlatform === clientPresets.tradingPlatform && existingClientPresets.tradingCurrency === clientPresets.tradingCurrency) {
      return enqueueSnackbar('These presets are already set', { variant: 'success' });
    }
    try {
      setIsLoading(true);
      const result = await userController.createClientPresetsRequest({ uuid: user.uuid, clientPresetsRequest: clientPresets });
      if (result?.requestStatus === 'PENDING') {
        setPending(true);
        enqueueSnackbar('Client presets have been submitted for review', { variant: 'success' });
      } else enqueueSnackbar('Client presets have been updated', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }

    return setIsLoading(false);
  };

  const changeCampaign = (e) => {
    let currentSettingIndex = -1;
    if (user.clientPresets && user.clientPresets.current) currentSettingIndex = user.clientPresets.current.findIndex((preset) => preset.campaignId === e.target.value);
    if (currentSettingIndex >= 0) {
      setExistingClientPresets(user.clientPresets.current[currentSettingIndex]);
      setClientPresets(user.clientPresets.current[currentSettingIndex]);
      if (user.clientPresets.current[currentSettingIndex].pending) setPending(true);
      else setPending(false);
    } else {
      setPending(false);
      setExistingClientPresets({ tradingPlatform: clientPresetOptions.tradingPlatform[0], tradingCurrency: clientPresetOptions.tradingCurrency[0] });
      setClientPresets({
        tradingPlatform: clientPresetOptions.tradingPlatform[0],
        tradingCurrency: clientPresetOptions.tradingCurrency[0],
        campaignId: e.target.value,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientPresets((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="widget client-presets split-widget-bottom" id="client-presets-box">
      <Blocker active={locked} title="Locked" description="You need to finish verification to use this feature" icon={<MdLockOutline className="icon" />} />
      <div className={`content-wrapper ${locked && 'blur'}`}>
        <h1 className="title disable-blur">{t('clientPresets.title')}</h1>
        <form className="onboard" onSubmit={submitClientPresets}>
          <p className="text">{t('clientPresets.text')}</p>
          <div className="field-group">
            <label htmlFor="campaignId" className="field-label">
              {t('clientPresets.campaign')}
            </label>
            {affiliateDataFetch && (
              <select
                className="optionsSelector"
                id="campaignId"
                name="campaignId"
                value={clientPresets.campaignId}
                onChange={(e) => changeCampaign(e)}
              >
                {Object.keys(affiliateDataFetch.campaigns).map((campaignOption) => (
                  <option key={campaignOption} value={campaignOption}>{affiliateDataFetch.campaigns[campaignOption].description}</option>
                ))}
              </select>
            )}
          </div>
          <div className="blockableWrapper">
            <Blocker active={pending} title="Pending" description="The preferences for the selected IB Deal are pending approval" />
            <div className={pending && 'blur'}>
              <div className="field-splitter">
                <div className="field-group">
                  <label htmlFor="tradingPlatform" className="field-label">
                    {t('clientPresets.tradingPlatform')}
                  </label>
                  <select
                    className="optionsSelector"
                    id="tradingPlatform"
                    name="tradingPlatform"
                    value={clientPresets.tradingPlatform}
                    onChange={(e) => handleChange(e)}
                  >
                    {clientPresetOptions.tradingPlatform.map((tradingPlatform) => (
                      <option key={tradingPlatform} value={tradingPlatform}>{tradingPlatform}</option>
                    ))}
                  </select>
                </div>
                <div className="field-group">
                  <label htmlFor="tradingCurrency" className="field-label">
                    {t('clientPresets.tradingCurrency')}
                  </label>
                  <select
                    className="optionsSelector"
                    id="tradingCurrency"
                    name="tradingCurrency"
                    value={clientPresets.tradingCurrency}
                    onChange={(e) => handleChange(e)}
                  >
                    {clientPresetOptions.tradingCurrency.map((tradingCurrency) => (
                      <option key={tradingCurrency} value={tradingCurrency}>{tradingCurrency}</option>
                    ))}
                  </select>
                </div>
              </div>
              <Button secondaryColor loading={isLoading} formButton text={t('submit')} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ClientPresets;
