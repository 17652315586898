import React, { useEffect, useState } from 'react';
import '../styles/components/IdleModal.scss';
import { useTranslation } from 'react-i18next';

export default function IdleModal({
  isOpen, initialMinute, logout, close, initialSeconds,
}) {
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  const { t } = useTranslation();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isOpen) {
      const myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            logout();
            clearInterval(myInterval);
          } else {
            setMinutes(minutes - 2);
            setSeconds(59);
          }
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  });

  const resetModal = () => {
    setMinutes(2);
    setSeconds(59);
    close();
  };

  return isOpen ? (
    <div className="modal">
      <div className="modal__overlay" />
      <div className="modal__container">
        <div className="modal__body">
          <div className="modal__body-container">
            <figure className="modal-close" />
            <div className="modal__content">
              <div>
                <h3>{t('dashboard.main.idlePopup.title')}</h3>
                <p>{t('dashboard.main.idlePopup.paragraphSecondary')}</p>
              </div>
              <div className="modal__countdown">
                <p className="minutes">
                  <span>{minutes}</span>
                  <br />
                  {t('dashboard.main.idlePopup.minutes')}
                </p>
                <div className="countdown-pointers">
                  <div className="point-1" />
                  <div className="point-2" />
                </div>
                <p className="seconds">
                  <span>
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </span>
                  <br />
                  {t('dashboard.main.idlePopup.seconds')}
                </p>
              </div>
              <div className="modal__controls">
                <button
                  type="button"
                  onClick={() => {
                    resetModal();
                  }}
                >
                  {t('dashboard.main.idlePopup.stayLoggedInButton')}
                </button>
                <button type="button" onClick={() => logout()}>{t('dashboard.main.idlePopup.logoutButton')}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
