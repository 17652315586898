import React, { useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import IdleTimer from 'react-idle-timer';
import {
  ChangePasswordPage,
  CreatePasswordPage,
  DashboardPage,
  DocumentVerificationPage,
  EmbedPage,
  LandingPage,
  LoginPage,
  NewPasswordPage,
  QuestionnairePage,
  RegisterPage,
  ResetPasswordPage,
  SignOutPage,
  VerifyEmailPage,
  VerifyEmailPasswordPage,
  VerifyNewEmailPage,
} from '../pages';
import { SupportBar } from '../components';
import IdleModal from '../components/IdleModal';

const entity = 'fsa';

const IntPage = ({ userAuthorized, setUserAuthorized }) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  function logOutUser() {
    const cookies = new Cookies();
    localStorage.clear();
    document.body.classList.remove('dark-mode');
    setUserAuthorized(false);
    cookies.remove('token', { path: '/' });
    history.push('/login');
  }

  const handleOnIdle = () => {
    setIsOpen(true);
  };

  return (
    <>
      {userAuthorized && (
      <>
        <IdleTimer timeout={1000 * 60 * 15} onIdle={(e) => handleOnIdle(e)} />
        <IdleModal
          isOpen={isOpen}
          initialMinute={2}
          initialSeconds={59}
          close={() => setIsOpen(false)}
          logout={() => {
            setIsOpen(false);
            logOutUser();
          }}
        />
      </>
      )}
      <SupportBar setUserAuthorized={setUserAuthorized} userAuthorized={userAuthorized} entity={entity} />
      <Switch>
        <Route path="/" exact component={() => <LandingPage setUserAuthorized={setUserAuthorized} entity={entity} />} />
        <Route path="/questionnaire" exact component={() => <QuestionnairePage setUserAuthorized={setUserAuthorized} entity={entity} />} />
        <Route path="/login" exact component={() => <LoginPage setUserAuthorized={setUserAuthorized} entity={entity} />} />
        <Route path="/register" exact component={() => <RegisterPage setUserAuthorized={setUserAuthorized} entity={entity} />} />
        <Route path="/documents" exact component={() => <DocumentVerificationPage setUserAuthorized={setUserAuthorized} entity={entity} />} />
        <Route path="/dashboard" component={() => <DashboardPage setUserAuthorized={setUserAuthorized} entity={entity} />} />
        <Route path="/portal" exact component={() => <EmbedPage setUserAuthorized={setUserAuthorized} entity={entity} />} />
        <Route path="/change-password" exact component={() => <ChangePasswordPage setUserAuthorized={setUserAuthorized} entity={entity} />} />
        <Route path="/verify-email" exact component={() => <VerifyEmailPage setUserAuthorized={setUserAuthorized} entity={entity} />} />
        <Route path="/verify-new-email/:uuid" exact component={() => <VerifyNewEmailPage setUserAuthorized={setUserAuthorized} entity={entity} />} />
        <Route path="/password-reset" exact component={() => <ResetPasswordPage setUserAuthorized={setUserAuthorized} entity={entity} />} />
        <Route path="/create-password" exact component={() => <CreatePasswordPage setUserAuthorized={setUserAuthorized} entity={entity} />} />
        <Route path="/verify-email-password" exact component={() => <VerifyEmailPasswordPage setUserAuthorized={setUserAuthorized} entity={entity} />} />
        <Route path="/new-password" exact component={() => <NewPasswordPage setUserAuthorized={setUserAuthorized} entity={entity} />} />
        <Route path="/sign-out" exact component={() => <SignOutPage setUserAuthorized={setUserAuthorized} entity={entity} />} />
      </Switch>
    </>
  );
};

export default IntPage;
