import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { elastic as Burger } from 'react-burger-menu';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { useSelector } from 'react-redux';
import Popover from '@mui/material/Popover';
import { useSnackbar } from 'notistack';
import '../../../styles/Dashboard/BurgerMenu.scss';
import LanguageSelector from '../../LanguageSelector';
import ThemeChanger from '../../../ThemeChanger';
import { BellIcon, BellIconDark, SettingsIcon, LogoutIcon, SettingsIconDark, LogoutIconDark } from '../../../media/images/icons';
import NewTag from '../../NewTag';
import { CustomBadge } from '../../CustomBadge';
import NotificationsModal from './Notifications/NotificationsModal';
import NotificationsPopover from './Notifications/NotificationsPopover';
import { notificationController } from '../../../controllers';
import { notificationsLimit } from '../../../utils/notificationConsts';

const BurgerMenuItem = ({
  svg, name, path, click, locked, showNew = false,
}) => {
  const location = useLocation();
  const isActive = location.pathname === path;
  const getTheme = useSelector((state) => state.theme);
  const isItDark = getTheme === 'dark' ? '-dark' : '';

  return (
    <Link className={`nd-burger-menu-item ${isActive ? 'active' : ''}`} to={path} role="button" tabIndex={0} onClick={click} style={{ position: 'relative' }}>
      {locked ? (
        <img src="/assets/dashboard/locked-icon.svg" id={name} alt={svg} />
      ) : (
        <img src={`/assets/dashboard/${svg}-icon${isActive ? '-active' : isItDark}.svg`} id={name} alt={svg} />
      )}
      <span className="nd-name">{name}</span>
      {showNew && <NewTag right="4px" top="-6px" />}
    </Link>
  );
};

const BurgerMenu = ({
  user, setUserAuthorized, locked, socket,
}) => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [userNotifications, setUserNotifications] = useState([]);
  const [hasUnread, setHasUnread] = useState(false);
  const [pageHasUnread, setPageHasUnread] = useState(false);
  const [socketResponse, setSocketResponse] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? 'simple-popover' : undefined;
  const { i18n } = useTranslation();
  const getTheme = useSelector((state) => state.theme);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const onMenuClick = () => {
    setMenuOpen(false);
  };

  const handleClickOnBellIcon = (event) => setAnchorEl(event.currentTarget);

  const handleCloseNotificationsPopover = () => {
    setAnchorEl(null);
  };

  const handleOpenNotificationsModal = () => {
    setAnchorEl(null);
    setIsModalOpen(true);
  };

  function logOutUser(event) {
    const cookies = new Cookies();
    event.preventDefault();
    localStorage.clear();
    document.body.classList.remove('dark-mode');
    setUserAuthorized(false);
    cookies.remove('token', { path: '/' });
    history.push('/login');
  }

  const loadNotificationsFromSocket = () => {
    // Here initialize the socket.io listener
    socket?.emit('newUser', user.uuid);

    socket.on('new-notification', (data) => {
      setSocketResponse(data);
      setHasUnread(true);
    });
  };

  async function fetchNotifications({ silent }) {
    try {
      const notificationsFetch = await notificationController.getNotifications({ accountId: user.uuid, limit: notificationsLimit });
      setUserNotifications(notificationsFetch.notifications);
      setHasUnread(notificationsFetch.hasUnread);
      setPageHasUnread(notificationsFetch.pageHasUnread);
      if (!silent) {
        enqueueSnackbar(t('dashboard.notifications.new'), {
          variant: 'default',
          autoHideDuration: 5000,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          style: { cursor: 'pointer' },
        });
      }
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  }

  useEffect(() => {
    if (socketResponse) fetchNotifications({ silent: false });
  }, [socketResponse]);

  useEffect(() => {
    fetchNotifications({ silent: true });
    loadNotificationsFromSocket();

    // TEST THIS
    // cleanup fn: close the connection when component unmounts
    return () => socket.disconnect();
  }, []);

  async function readNotifications() {
    if (!pageHasUnread) return;
    try {
      await notificationController.markManyNotificationsAsRead({ notifications: userNotifications });
      fetchNotifications({ silent: true });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  useEffect(() => {
    if ((openPopover || isModalOpen) && pageHasUnread) setTimeout(readNotifications, 3000);
  }, [openPopover, pageHasUnread]);

  useEffect(async () => {
    const storedLanguage = await JSON.parse(localStorage.getItem('language'));
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    }
  }, []);

  const setSelectSettingsIcon = () => {
    if (getTheme === 'dark') {
      return (
        <img src={SettingsIconDark} alt="settings-dark" id="settings-selector-box" />
      );
    }
    return (
      <img
        id="settings-selector-box"
        src={SettingsIcon}
        alt="settings"
      />
    );
  };

  const setBellIconStyle = () => {
    if (getTheme === 'dark') {
      return <img src={BellIconDark} title="notifications" alt="bell-icon" id="bell-icon" />;
    }
    return (
      <img src={BellIcon} title="notifications" alt="bell-icon" id="bell-icon" />);
  };

  const setSelectLogoutIcon = () => {
    if (getTheme === 'dark') {
      return (
        <img
          src={LogoutIconDark}
          className="logout-icon"
          alt="logout-dark"
          title="Log out"
        />
      );
    }
    return (
      <img
        src={LogoutIcon}
        className="logout-icon"
        alt="logout"
        title="Log out"
      />
    );
  };

  return (
    <div className="nd-burger-menu">
      <NotificationsModal
        open={isModalOpen}
        handleClose={(page) => {
          setIsModalOpen(false);
          if (page > 0) fetchNotifications({ silent: true });
        }}
        notifications={userNotifications}
        hasUnread={hasUnread}
        accountId={user.uuid}
        setUserNotifications={setUserNotifications}
        setHasUnread={setHasUnread}
      />
      <Burger isOpen={menuOpen} onClose={() => setMenuOpen(false)} onOpen={() => setMenuOpen(true)}>
        <BurgerMenuItem name={t('dashboard.sideMenu.dashboard')} svg="dashboard" path="/dashboard/" click={onMenuClick} />
        <BurgerMenuItem name={t('dashboard.sideMenu.wallet')} svg="wallet" path="/dashboard/wallet" locked={locked} click={onMenuClick} />
        <BurgerMenuItem name={t('dashboard.sideMenu.marketing')} svg="promo" path="/dashboard/marketing" locked={locked} click={onMenuClick} />
        <BurgerMenuItem name={t('dashboard.sideMenu.tradingTools')} svg="trading-tools" path="/dashboard/trading-tools" locked={locked} click={onMenuClick} showNew />
        <BurgerMenuItem name={t('dashboard.sideMenu.education')} svg="education" path="/dashboard/education" click={onMenuClick} />
        <BurgerMenuItem name={t('dashboard.sideMenu.analytics')} svg="portal" path="/dashboard/portal" locked={locked} click={onMenuClick} />
        <BurgerMenuItem name={t('dashboard.sideMenu.products')} svg="products" path="/dashboard/products" click={onMenuClick} />
        <BurgerMenuItem name={t('dashboard.sideMenu.promoClubs')} svg="club" path="/dashboard/promo-clubs" click={onMenuClick} />
        <div className="side-menu-toggle">
          <ThemeChanger />
        </div>
        <div className="tools-section">
          <div className="vertical-separator">
            <LanguageSelector selected={selectedLanguage} setSelected={setSelectedLanguage} userUuid={user.uuid} />
          </div>
          <div className="vertical-separator">
            <Link to="/dashboard/profile" className="nd-setting-icon">
              {setSelectSettingsIcon()}
            </Link>
          </div>
          <div className="vertical-separator">
            <div
              role="button"
              tabIndex="0"
              onClick={handleClickOnBellIcon}
              onKeyDown={handleClickOnBellIcon}
              style={{ cursor: 'pointer' }}
            >
              <CustomBadge
                variant="dot"
                invisible={!hasUnread}
              >
                {setBellIconStyle()}
              </CustomBadge>
            </div>
            <Popover
              id={popoverId}
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handleCloseNotificationsPopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              style={{ marginTop: '20px' }}
            >
              <NotificationsPopover openModal={isModalOpen} handleOpenNotificationsModal={handleOpenNotificationsModal} notifications={userNotifications} hasUnread={hasUnread} accountId={user.uuid} setUserNotifications={setUserNotifications} setHasUnread={setHasUnread} />
            </Popover>
          </div>
          <div
            onClick={logOutUser}
            onKeyDown={logOutUser}
            role="button"
            tabIndex="0"
          >
            {setSelectLogoutIcon()}
          </div>
        </div>
      </Burger>
    </div>
  );
};

export default BurgerMenu;
