import React, { useEffect, useState } from 'react';
import './styles/DarkModeStyles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Switch from './components/Switch';
import { userController } from './controllers';
import './styles/Dashboard/Widgets.scss';

const ThemeChanger = () => {
  const appTheme = useSelector((state) => state.theme);
  const appThemeCache = localStorage.getItem('app-theme');
  let user = {};
  const [uuid, setUserUuid] = useState('');
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  //  const [user, setUser] = useState();
  function changeThemeOnRedux(theme) {
    dispatch({
      type: 'CHANGE_THEME',
      theme,
    });
  }

  const updateThemeOnDb = async (theme) => {
    try {
      if (uuid) await userController.updateUser(uuid, { theme });
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  };
  function getCacheUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  function setUserThemeCache(theme) {
    user = getCacheUser();
    if (user !== null) {
      user.theme = theme;
    }
    localStorage.setItem('user', JSON.stringify(user));
  }

  function updateLayout(theme) {
    changeThemeOnRedux(theme);
    updateThemeOnDb(theme);
    setUserThemeCache(theme);
    localStorage.setItem('app-theme', theme);
  }

  const handleChange = () => {
    if (appTheme === 'light') {
      updateLayout('dark');
      document.body.classList.add('dark-mode');
    } else {
      updateLayout('light');
      document.body.classList.remove('dark-mode');
    }
  };

  function checkForThemeChange() {
    if (user !== null) {
      if (appTheme === 'dark') {
        updateLayout(appTheme);
        user = getCacheUser();
      }
      if (user === null) {
        localStorage.setItem('app-theme', appTheme);
        changeThemeOnRedux(appTheme);
      } else {
        localStorage.setItem('app-theme', user.theme);
        changeThemeOnRedux(user.theme);
      }
    } else {
      changeThemeOnRedux(appThemeCache);
    }
    if (appThemeCache === 'dark') {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }

  useEffect(() => {
    if (localStorage.getItem('user') !== null) {
      user = getCacheUser();
      if (user !== null) {
        setUserUuid(user.uuid);
      }
    }
    checkForThemeChange();
  }, []);

  return (
    <div className="toogle-switch-container">
      <Switch isDarkModeOn={appTheme === 'dark'} handleChange={handleChange} />
    </div>
  );
};

export default ThemeChanger;
