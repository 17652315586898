import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import ClipLoader from 'react-spinners/ClipLoader';
import { useTranslation } from 'react-i18next';
import { notificationController } from '../../../../controllers';
import '../../../../styles/components/Notifications.scss';
import NotificationsList from './NotificationsList';
import { notificationsLimit } from '../../../../utils/notificationConsts';

const NotificationsPopover = ({
  handleOpenNotificationsModal, notificationsCount, setNotificationsCount, notifications, hasUnread, accountId, setUserNotifications, setHasUnread,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setIsLoading] = useState(false);
  const clipLoaderColor = '#50B848';
  const { t } = useTranslation();

  async function fetchNotifications() {
    try {
      const notificationsFetch = await notificationController.getNotifications({ accountId, limit: notificationsLimit });
      setUserNotifications(notificationsFetch.notifications);
      setHasUnread(notificationsFetch.hasUnread);
      setNotificationsCount(notificationsFetch.notificationsCount);
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  }

  const handleMarkAllAsRead = async () => {
    if (notifications.length && hasUnread) {
      try {
        setIsLoading(true);
        await notificationController.markAllNotificationsAsRead({ accountId });
        fetchNotifications();
        setHasUnread(false);
        enqueueSnackbar(t('dashboard.notifications.allRead'), { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="panel">
        <span className="panel-title">
          {t('dashboard.notifications.title')}
        </span>
        {notifications.length ? (
          <>
            {hasUnread && (loading ? (
              <div className="clip-loader-div">
                <ClipLoader color={clipLoaderColor} css="display: flex" size={15} />
              </div>
            ) : (
              <div role="button" tabIndex={0} onClick={handleMarkAllAsRead} onKeyDown={handleMarkAllAsRead} className="mark-read-text">
                <span>{t('dashboard.notifications.markAsRead')}</span>
              </div>
            )
            )}
            <NotificationsList notifications={notifications} />
            {notificationsCount > 5 && (
            <div role="button" tabIndex={0} onClick={handleOpenNotificationsModal} onKeyDown={handleOpenNotificationsModal}>
              <p className="see-more-text">
                {t('dashboard.notifications.seeMore')}
              </p>
            </div>
            )}
          </>
        ) : (
          <p className="no-notifications-msg">
            {t('dashboard.notifications.noNotifications')}
          </p>
        )}
      </div>
    </>
  );
};

export default NotificationsPopover;
