import { getSideAsText } from '../utils/utilFunctions';

export const parseKYCDocuments = ({ uploadDocuments }) => {
  const parsedDocuments = {
    identity: null,
    identityRejection: null,
    address: null,
    addressRejection: null,
  };

  const proofOfId = uploadDocuments.filter((x) => x.documentGroup === 'PROOF_OF_ID');
  const proofOfAddress = uploadDocuments.filter((x) => x.documentGroup === 'PROOF_OF_ADDRESS');

  if (proofOfId.length) {
    const rejected = proofOfId.filter((x) => x.documentStatus === 'Rejected');
    const pending = proofOfId.filter((x) => x.documentStatus === 'Pending');
    if (rejected.length) {
      parsedDocuments.identity = rejected[0].documentStatus;

      const rejectReason = [`${getSideAsText(rejected[0].documentType)}: ${rejected[0].rejectionReason}`];
      if (rejected[1]) rejectReason.push(`${getSideAsText(rejected[1].documentType)}: ${rejected[1].rejectionReason}`);

      parsedDocuments.identityRejection = rejectReason;
    } else if (pending.length) parsedDocuments.identity = pending[0].documentStatus;
    else parsedDocuments.identity = proofOfId[0].documentStatus;
  }
  if (proofOfAddress.length) {
    parsedDocuments.address = proofOfAddress[0].documentStatus;
    if (proofOfAddress[0].documentStatus === 'Rejected') {
      parsedDocuments.addressRejection = `${getSideAsText(proofOfAddress[0].documentType)}: ${proofOfAddress[0].rejectionReason}`;
    }
  }

  return parsedDocuments;
};
