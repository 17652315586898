import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import '../../styles/components/kyc/onboardingProgress.scss';

export default function OnboardingProgress({ userStage }) {
  const { t } = useTranslation();

  return (
    <div className="onboarding-progress">
      <div className="progress-step">
        {userStage.contract === 'APPROVED' ? (
          <IoCheckmarkCircleSharp className="progress-icon" />
        ) : null}
        <p className="progress-digit">
          0
          <span style={{ letterSpacing: 5 }}>1</span>
        </p>
        <p className="progress-label">{t('documentUpload.contract')}</p>
      </div>
      <hr className="splitter" />
      <div className="progress-step">
        {userStage.identity === 'Approved' ? (
          <IoCheckmarkCircleSharp className="progress-icon" />
        ) : null}
        <p className="progress-digit">
          0
          <span style={{ letterSpacing: 5 }}>2</span>
        </p>
        <p className="progress-label">{t('documentUpload.identity')}</p>
      </div>
      <hr className="splitter" />
      <div className="progress-step">
        {userStage.address === 'Approved' ? (
          <IoCheckmarkCircleSharp className="progress-icon" />
        ) : null}
        <p className="progress-digit">03</p>
        <p className="progress-label">{t('documentUpload.address')}</p>
      </div>
    </div>
  );
}
