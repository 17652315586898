import React, { useState } from 'react';
import { RiArrowRightSLine, RiArrowDownSLine, RiInformationLine } from 'react-icons/ri';
import '../../styles/components/kyc/linkBox.scss';

export default function LinkBox({ icon, greyedOut, open, title, status, rejectionReason, titleNote, disabled, toggleOpen, helper, children }) {
  const [showHelper, setShowHelper] = useState(false);

  return (
    <div className={`link-box ${greyedOut && 'inactive'} ${disabled && 'disabled'} ${!open && 'closed-link'}`}>
      <button type="button" disabled={disabled} className="onboarding-link" onClick={() => toggleOpen()}>
        <div className="icon-title">
          <img src={icon} className="icon" alt="Icon" />
          <h2 className={`link-title ${titleNote && 'with-note'}`}>
            {title}
            {titleNote && greyedOut && <span className="questionnaire-note">{titleNote}</span>}
            {helper && (
              <RiInformationLine
                className={`info-icon ${!open && 'hide-info-icon'}`}
                onMouseEnter={() => setShowHelper(true)}
                onMouseLeave={() => setShowHelper(false)}
              />
            )}
          </h2>
          {helper && <div className={showHelper ? 'help-box' : 'help-box hide-help-box'}>{helper}</div>}
        </div>
        {status ? rejectionReason ? (
          <div className="rejected-container">
            <p className={`status-text status-${status.toLowerCase()}`}>{status}</p>
            {rejectionReason && <p className="reason-text">{rejectionReason}</p>}
          </div>
        ) : (<p className={`status-text status-${status.toLowerCase()}`}>{status}</p>
        ) : open ? <RiArrowDownSLine className="arrow-icon" /> : <RiArrowRightSLine className="arrow-icon" />}
      </button>
      {children}
    </div>
  );
}
