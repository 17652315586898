import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import '../../styles/Onboarding/UserData.scss';
import { useTranslation } from 'react-i18next';
import { RiArrowRightSLine, RiArrowLeftSLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import { FaceScanIcon, SignatureIcon, GeoTagIcon, ExampleIdIcon, ClientPresetIcon } from '../../media/images/icons';
import { Identity, Address, Campaign, ClientPresets, LinkBox, SocialBox, OnboardingProgress } from '../kyc';
import UploadThankYouModal from './UploadThankYouModal';
import { userController, documentController } from '../../controllers';
import { userHelper } from '../../helpers';
import ThemeChanger from '../../ThemeChanger';

export default function UserData({ user }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [showIdentityForm, setShowIdentityForm] = useState(false);
  const [showContractForm, setShowContractForm] = useState(false);
  const [showClientPresetsForm, setShowClientPresetsForm] = useState(false);
  const [allowClientPresets, setAllowClientPresets] = useState(false);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [userStage, setUserStage] = useState({ identity: null, address: null, contract: null, clientPreset: null });
  const [fileDataChanged, setFileDateChanged] = useState(0);
  const [uploadThankYouPopup, showUploadThankYouPopup] = useState(false);
  const [uploadedSomething, setUploadedSomething] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!uploadedSomething) return;
    if (userStage.identity === 'Pending' && userStage.address === 'Pending' && userStage.contract === 'PENDING') {
      showUploadThankYouPopup(true);
      setUploadedSomething(false);
    }
  }, [userStage, uploadedSomething]);

  useEffect(() => {
    (async () => {
      let uploadDocuments;
      const localUser = await JSON.parse(localStorage.getItem('user'));
      const fetchedUser = await userController.getUser(localUser.uuid);

      try {
        uploadDocuments = await documentController.getDocuments(localUser.uuid);
      } catch (error) {
        return enqueueSnackbar(error.message, { variant: 'error' });
      }

      const updatedValue = userStage;

      if (fetchedUser.campaignRequests) {
        const registrationCampaign = fetchedUser.campaignRequests.filter((campaign) => campaign.onRegistration)[0];
        if (registrationCampaign) {
          updatedValue.contract = registrationCampaign.status;
          if (registrationCampaign.status === 'APPROVED' && registrationCampaign.type !== 'master') setAllowClientPresets(true);
        }
      }

      if (fetchedUser.clientPresets?.requests?.length) {
        const sortedClientPresetRequests = fetchedUser.clientPresets.requests.sort((a, b) => new Date(b.requestTimestamp) - new Date(a.requestTimestamp));
        updatedValue.clientPreset = sortedClientPresetRequests[0].requestStatus;
      }

      if (uploadDocuments.length) {
        const parsedDocs = await userHelper.parseKYCDocuments({ uploadDocuments });
        if (parsedDocs.address) updatedValue.address = parsedDocs.address;
        if (parsedDocs.addressRejection) updatedValue.addressRejection = parsedDocs.addressRejection;
        if (parsedDocs.identity) updatedValue.identity = parsedDocs.identity;
        if (parsedDocs.identityRejection) updatedValue.identityRejection = parsedDocs.identityRejection;
      }

      return setUserStage({ ...userStage, ...updatedValue });
    })();
  }, [fileDataChanged]);

  return (
    <div className="user-data">
      <UploadThankYouModal
        showPopup={uploadThankYouPopup}
        onClose={() => showUploadThankYouPopup(false)}
      />
      <div className="user-data-inner container">
        <div className="topRowButtons">
          {!user.questionnaireCompleted
            && (
              <button
                type="button"
                className="questionnaire-btn"
                name="questionnaire"
                onClick={() => {
                  history.push('/questionnaire');
                }}
              >
                <RiArrowLeftSLine className="arrow-icon" />
                {t('documentUpload.questionnaire')}
              </button>
            )}
          {user.questionnaireCompleted && (<ThemeChanger />)}
          <button
            type="button"
            className="dashboard-btn"
            name="dashboard"
            onClick={() => {
              history.push('/dashboard');
            }}
          >
            {t('documentUpload.dashboard')}
            <RiArrowRightSLine className="arrow-icon" />
          </button>
        </div>
        <OnboardingProgress userStage={userStage} />
        <h2 className="title">
          {t('hello.label')}
          {' '}
          {user.firstName}
          !
        </h2>
        <p className="note">{t('documentUpload.subtitle')}</p>
        <div className="links-container">
          {/* IB Deal */}
          <LinkBox
            icon={SignatureIcon}
            greyedOut={!user.questionnaireCompleted}
            open={showContractForm}
            title={t('documentUpload.contract')}
            status={userStage.contract}
            titleNote={t('documentUpload.questionnaireBlock')}
            disabled={userStage.contract === 'APPROVED' || userStage.contract === 'PENDING' || !user.questionnaireCompleted}
            toggleOpen={() => setShowContractForm(!showContractForm)}
          >
            <Campaign
              onUploadSuccess={() => {
                setShowContractForm(false);
                setUploadedSomething(true);
                setUserStage({
                  ...userStage,
                  contract: 'PENDING',
                });
              }}
            />
          </LinkBox>

          {/* Client Presets */}
          {allowClientPresets && (
            <LinkBox
              icon={ClientPresetIcon}
              open={showClientPresetsForm}
              title={t('clientPresets.title')}
              status={userStage.clientPreset}
              disabled={userStage.clientPreset === 'PENDING'}
              toggleOpen={() => setShowClientPresetsForm(!showClientPresetsForm)}
            >
              <ClientPresets
                onUploadSuccess={({ requestStatus }) => {
                  setShowClientPresetsForm(false);
                  setUploadedSomething(true);
                  setUserStage({ ...userStage, clientPreset: requestStatus });
                }}
              />
            </LinkBox>
          )}

          {/* POI */}
          <LinkBox
            icon={FaceScanIcon}
            open={showIdentityForm}
            title={t('documentUpload.identity')}
            status={userStage.identity}
            disabled={userStage.identity === 'Approved' || userStage.identity === 'Pending'}
            toggleOpen={() => setShowIdentityForm(!showIdentityForm)}
            helper={(
              <>
                <p>
                  {t('documentUpload.validGovernment')}
                  <br />
                  {t('documentUpload.residencePermit')}
                </p>
                <img src={ExampleIdIcon} className="icon" height="100" alt="Example ID" />
              </>
            )}
          >
            <Identity
              onUploadSuccess={() => {
                setFileDateChanged(Date.now());
                setShowIdentityForm(false);
                setUploadedSomething(true);
              }}
            />
          </LinkBox>

          {/* POR */}
          <LinkBox
            icon={GeoTagIcon}
            open={showAddressForm}
            title={t('documentUpload.address')}
            status={userStage.address}
            disabled={userStage.address === 'Approved' || userStage.address === 'Pending'}
            toggleOpen={() => setShowAddressForm(!showAddressForm)}
          >
            <Address
              onUploadSuccess={() => {
                setFileDateChanged(Date.now());
                setShowAddressForm(false);
                setUploadedSomething(true);
              }}
            />
          </LinkBox>
        </div>

        {/* Social Box */}
        <SocialBox user={user} />
      </div>
    </div>
  );
}
