export const languageOptions = [
  { key: 'en', name: 'English' },
  { key: 'es', name: 'Spanish' },
];

export const sizeOptions = [
  { width: '120', height: '240' },
  { width: '120', height: '600' },
  { width: '160', height: '600' },
  { width: '180', height: '150' },
  { width: '200', height: '200' },
  { width: '234', height: '60' },
  { width: '250', height: '250' },
  { width: '300', height: '250' },
  { width: '300', height: '600' },
  { width: '320', height: '50' },
  { width: '336', height: '280' },
  { width: '468', height: '60' },
  { width: '728', height: '90' },
  { width: '880', height: '116' },
  { width: '970', height: '90' },
  { width: '970', height: '250' },
  { width: '1050', height: '650' },
];

export const bannerList = [
  {
    theme: {
      key: 'app',
      name: 'The Trading App You’ll Love',
    },
    size: {
      en: [
        { width: '120', height: '240' },
        { width: '120', height: '600' },
        { width: '160', height: '600' },
        { width: '180', height: '150' },
        { width: '200', height: '200' },
        { width: '234', height: '60' },
        { width: '250', height: '250' },
        { width: '300', height: '250' },
        { width: '300', height: '600' },
        { width: '320', height: '50' },
        { width: '336', height: '280' },
        { width: '468', height: '60' },
        { width: '728', height: '90' },
        { width: '970', height: '90' },
        { width: '970', height: '250' },
        { width: '1050', height: '650' },
      ],
      es: [
        { width: '120', height: '240' },
        { width: '120', height: '600' },
        { width: '160', height: '600' },
        { width: '200', height: '200' },
        { width: '234', height: '60' },
        { width: '250', height: '250' },
        { width: '300', height: '250' },
        { width: '300', height: '600' },
        { width: '336', height: '280' },
        { width: '468', height: '60' },
        { width: '728', height: '90' },
        { width: '970', height: '90' },
        { width: '970', height: '250' },
      ],
    },
  },
  {
    theme: {
      key: 'broker',
      name: 'More Than a Broker',
    },
    size: {
      en: [
        { width: '120', height: '240' },
        { width: '120', height: '600' },
        { width: '160', height: '600' },
        { width: '200', height: '200' },
        { width: '234', height: '60' },
        { width: '250', height: '250' },
        { width: '300', height: '250' },
        { width: '300', height: '600' },
        { width: '336', height: '280' },
        { width: '468', height: '60' },
        { width: '728', height: '90' },
        { width: '970', height: '90' },
        { width: '970', height: '250' },
      ],
      es: [
        { width: '120', height: '240' },
        { width: '120', height: '600' },
        { width: '160', height: '600' },
        { width: '200', height: '200' },
        { width: '234', height: '60' },
        { width: '250', height: '250' },
        { width: '300', height: '250' },
        { width: '300', height: '600' },
        { width: '336', height: '280' },
        { width: '468', height: '60' },
        { width: '728', height: '90' },
        { width: '970', height: '90' },
        { width: '970', height: '250' },
      ],
    },
  },
  {
    theme: {
      key: 'demo',
      name: 'Learn Trading With a Free Demo Account',
    },
    size: {
      en: [
        { width: '120', height: '240' },
        { width: '120', height: '600' },
        { width: '160', height: '600' },
        { width: '200', height: '200' },
        { width: '234', height: '60' },
        { width: '250', height: '250' },
        { width: '300', height: '250' },
        { width: '300', height: '600' },
        { width: '336', height: '280' },
        { width: '468', height: '60' },
        { width: '728', height: '90' },
        { width: '970', height: '90' },
        { width: '970', height: '250' },
      ],
      es: [
        { width: '120', height: '240' },
        { width: '120', height: '600' },
        { width: '160', height: '600' },
        { width: '200', height: '200' },
        { width: '234', height: '60' },
        { width: '250', height: '250' },
        { width: '300', height: '250' },
        { width: '300', height: '600' },
        { width: '336', height: '280' },
        { width: '468', height: '60' },
        { width: '728', height: '90' },
        { width: '970', height: '90' },
        { width: '970', height: '250' },
      ],
    },
  },
  {
    theme: {
      key: 'cfd',
      name: 'Trade CFDs With a Regulated Broker',
    },
    size: {
      en: [
        { width: '120', height: '240' },
        { width: '120', height: '600' },
        { width: '160', height: '600' },
        { width: '200', height: '200' },
        { width: '234', height: '60' },
        { width: '250', height: '250' },
        { width: '300', height: '250' },
        { width: '300', height: '600' },
        { width: '336', height: '280' },
        { width: '468', height: '60' },
        { width: '728', height: '90' },
        { width: '970', height: '90' },
        { width: '970', height: '250' },
      ],
      es: [
        { width: '120', height: '240' },
        { width: '120', height: '600' },
        { width: '160', height: '600' },
        { width: '200', height: '200' },
        { width: '234', height: '60' },
        { width: '250', height: '250' },
        { width: '300', height: '250' },
        { width: '300', height: '600' },
        { width: '336', height: '280' },
        { width: '468', height: '60' },
        { width: '728', height: '90' },
        { width: '970', height: '90' },
        { width: '970', height: '250' },
      ],
    },
  },
  {
    theme: {
      key: 'crypto',
      name: 'Trade Top Cryptos With a Regulated Broker',
    },
    size: {
      en: [
        { width: '120', height: '240' },
        { width: '120', height: '600' },
        { width: '160', height: '600' },
        { width: '180', height: '150' },
        { width: '200', height: '200' },
        { width: '234', height: '60' },
        { width: '250', height: '250' },
        { width: '300', height: '250' },
        { width: '300', height: '600' },
        { width: '320', height: '50' },
        { width: '336', height: '280' },
        { width: '468', height: '60' },
        { width: '728', height: '90' },
        { width: '970', height: '90' },
        { width: '970', height: '250' },
        { width: '1050', height: '650' },
      ],
      es: [
        { width: '120', height: '240' },
        { width: '120', height: '600' },
        { width: '160', height: '600' },
        { width: '200', height: '200' },
        { width: '234', height: '60' },
        { width: '250', height: '250' },
        { width: '300', height: '250' },
        { width: '300', height: '600' },
        { width: '336', height: '280' },
        { width: '468', height: '60' },
        { width: '728', height: '90' },
        { width: '970', height: '90' },
        { width: '970', height: '250' },
      ],
    },
  },
  {
    theme: {
      key: 'default',
      name: 'Where Values Matter',
    },
    size: {
      en: [
        { width: '120', height: '240' },
        { width: '120', height: '600' },
        { width: '160', height: '600' },
        { width: '180', height: '150' },
        { width: '200', height: '200' },
        { width: '234', height: '60' },
        { width: '250', height: '250' },
        { width: '300', height: '250' },
        { width: '300', height: '600' },
        { width: '320', height: '50' },
        { width: '336', height: '280' },
        { width: '468', height: '60' },
        { width: '728', height: '90' },
        { width: '880', height: '116' },
        { width: '970', height: '90' },
        { width: '970', height: '250' },
        { width: '1050', height: '650' },
      ],
      es: [
        { width: '120', height: '240' },
        { width: '120', height: '600' },
        { width: '160', height: '600' },
        { width: '200', height: '200' },
        { width: '234', height: '60' },
        { width: '250', height: '250' },
        { width: '300', height: '250' },
        { width: '300', height: '600' },
        { width: '336', height: '280' },
        { width: '468', height: '60' },
        { width: '728', height: '90' },
        { width: '970', height: '90' },
        { width: '970', height: '250' },
      ],
    },
  },
  {
    theme: {
      key: 'instruments',
      name: 'Trade 300+ Instruments With a Regulated Broker',
    },
    size: {
      en: [
        { width: '120', height: '240' },
        { width: '120', height: '600' },
        { width: '160', height: '600' },
        { width: '200', height: '200' },
        { width: '234', height: '60' },
        { width: '250', height: '250' },
        { width: '300', height: '250' },
        { width: '300', height: '600' },
        { width: '336', height: '280' },
        { width: '468', height: '60' },
        { width: '728', height: '90' },
        { width: '970', height: '90' },
        { width: '970', height: '250' },
      ],
      es: [
        { width: '120', height: '240' },
        { width: '120', height: '600' },
        { width: '160', height: '600' },
        { width: '200', height: '200' },
        { width: '234', height: '60' },
        { width: '250', height: '250' },
        { width: '300', height: '250' },
        { width: '300', height: '600' },
        { width: '336', height: '280' },
        { width: '468', height: '60' },
        { width: '728', height: '90' },
        { width: '970', height: '90' },
        { width: '970', height: '250' },
      ],
    },
  },
  {
    theme: {
      key: 'trading',
      name: 'Start Trading',
    },
    size: {
      en: [
        { width: '120', height: '240' },
        { width: '120', height: '600' },
        { width: '160', height: '600' },
        { width: '200', height: '200' },
        { width: '234', height: '60' },
        { width: '250', height: '250' },
        { width: '300', height: '250' },
        { width: '300', height: '600' },
        { width: '336', height: '280' },
        { width: '468', height: '60' },
        { width: '728', height: '90' },
        { width: '970', height: '90' },
        { width: '970', height: '250' },
      ],
      es: [
        { width: '120', height: '240' },
        { width: '120', height: '600' },
        { width: '160', height: '600' },
        { width: '200', height: '200' },
        { width: '234', height: '60' },
        { width: '250', height: '250' },
        { width: '300', height: '250' },
        { width: '300', height: '600' },
        { width: '336', height: '280' },
        { width: '468', height: '60' },
        { width: '728', height: '90' },
        { width: '970', height: '90' },
        { width: '970', height: '250' },
      ],
    },
  },
];
