import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Popover from '@mui/material/Popover';
import socketIOClient from 'socket.io-client';
import NotificationsModal from './components/Notifications/NotificationsModal';
import { BellIcon, BellIconDark, SettingsIcon, LogoutIcon, SettingsIconDark, LogoutIconDark } from '../../media/images/icons';
import { MiniBalanceLabel, WebSideMenu, ProfileLabel, BurgerMenu, OnboardingProgress } from './components';
import { CustomBadge } from '../CustomBadge';
import SupportForm from '../SupportForm';
import LanguageSelector from '../LanguageSelector';
import NotificationsPopover from './components/Notifications/NotificationsPopover';
import { VirtualTourPopup } from './components/VirtualTour';
import DarkIcon from '../../media/images/logos/axianceDark.svg';
import { notificationController, userController } from '../../controllers';
import { notificationsLimit } from '../../utils/notificationConsts';

const socket = socketIOClient(process.env.REACT_APP_BACKEND_URL);

function Layout({
  children, user, entity, setUserAuthorized, locked,
}) {
  const [socketResponse, setSocketResponse] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [userNotifications, setUserNotifications] = useState([]);
  const [hasUnread, setHasUnread] = useState(false);
  const [pageHasUnread, setPageHasUnread] = useState(false);
  const [notificationsCount, setNotificationsCount] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showVirtualTour, setShowVirtualTour] = useState(false);
  const { enqueueSnackbar } = useSnackbar;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  let getTheme = useSelector((state) => state.theme);
  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? 'simple-popover' : undefined;

  //  i added these two functions from the themechanger separately because if you login with dark mode you have to update the db.
  function changeThemeOnRedux(theme) {
    dispatch({
      type: 'CHANGE_THEME',
      theme,
    });
  }

  const updateThemeOnDb = async (theme) => {
    try {
      if (user.uuid) await userController.updateUser(user.uuid, { theme });
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  };

  function hideSupportForm() {
    setShowPopup(false);
    setShowVirtualTour(false);
  }

  function logOutUser(event) {
    const cookies = new Cookies();
    event.preventDefault();
    localStorage.clear();
    //  that it dont discard the theme settings
    localStorage.setItem('app-theme', getTheme);
    document.body.classList.remove('dark-mode');
    setUserAuthorized(false);
    cookies.remove('token', { path: '/' });
    history.push('/login');
  }

  const handleClickOnBellIcon = (event) => setAnchorEl(event.currentTarget);

  const handleCloseNotificationsPopover = () => setAnchorEl(null);

  const handleOpenNotificationsModal = () => {
    setAnchorEl(null);
    setIsModalOpen(true);
  };

  async function fetchNotifications({ silent }) {
    try {
      const notificationsFetch = await notificationController.getNotifications({ accountId: user.uuid, limit: notificationsLimit });
      setUserNotifications(notificationsFetch.notifications);
      setHasUnread(notificationsFetch.hasUnread);
      setPageHasUnread(notificationsFetch.pageHasUnread);
      setNotificationsCount(notificationsFetch.notificationsCount);
      if (!silent) {
        enqueueSnackbar(t('dashboard.notifications.new'), {
          variant: 'default',
          autoHideDuration: 5000,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          style: { cursor: 'pointer' },
        });
      }
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  }

  useEffect(() => {
    if (socketResponse) fetchNotifications({ silent: false });
  }, [socketResponse]);

  const loadNotificationsFromSocket = () => {
    // Here initialize the socket.io listener
    socket?.emit('newUser', user.uuid);

    // RECEIVE NEW NOTIFICATION THROUGH SOCKET
    socket.on('new-notification', (data) => {
      setSocketResponse(data);
    });
  };

  useEffect(() => {
    fetchNotifications({ silent: true });
    loadNotificationsFromSocket();

    // // TEST THIS
    // // cleanup fn: close the connection when component unmounts
    // return () => socket.disconnect();
  }, []);

  useEffect(async () => {
    const storedLanguage = await JSON.parse(localStorage.getItem('language'));
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    } else {
      const userLanguage = user.language;
      localStorage.setItem('language', JSON.stringify(userLanguage));
      setSelectedLanguage(userLanguage);
      i18n.changeLanguage(userLanguage);
    }
  }, []);

  useEffect(() => {
    const appThemeCache = localStorage.getItem('app-theme');
    if (appThemeCache !== null) {
      if (appThemeCache === 'dark') {
        getTheme = 'dark';
        updateThemeOnDb('dark');
        changeThemeOnRedux('dark');
      }
    }
  }, [getTheme]);

  useEffect(async () => {
    if (user && !user.walkThroughCompleted) {
      setShowVirtualTour(true);
      try {
        await userController.tourCompleted(true);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  }, [user]);

  async function readNotifications() {
    try {
      if (!pageHasUnread) return;
      await notificationController.markManyNotificationsAsRead({ notifications: userNotifications });
      fetchNotifications({ silent: true });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  useEffect(() => {
    if ((openPopover || isModalOpen) && pageHasUnread) setTimeout(readNotifications, 3000);
  }, [openPopover, pageHasUnread]);

  const setSelectLogo = () => {
    if (getTheme === 'dark') {
      return (
        <img src={DarkIcon} alt="close" />
      );
    }
    return (
      <img src="https://client.axiance.com/static/media/logo.5683ce9d.svg" alt="axiance" />
    );
  };

  const setSelectSettingsIcon = () => {
    if (getTheme === 'dark') {
      return (
        <img src={SettingsIconDark} alt="settings-dark" id="settings-selector-box" />
      );
    }
    return (
      <img
        id="settings-selector-box"
        src={SettingsIcon}
        alt="settings"
      />
    );
  };

  const setSelectLogoutIcon = () => {
    if (getTheme === 'dark') {
      return (
        <img
          src={LogoutIconDark}
          className="logout-icon"
          alt="logout-dark"
          title="Log out"
        />
      );
    }
    return (
      <img
        src={LogoutIcon}
        className="logout-icon"
        alt="logout"
        title="Log out"
      />
    );
  };

  const setBellIconStyle = () => {
    if (getTheme === 'dark') {
      return <img src={BellIconDark} title="notifications" alt="bell-icon" id="notifications-selector-box" />;
    }
    return (<img src={BellIcon} title="notifications" alt="bell-icon" id="notifications-selector-box" />);
  };

  return (
    <>
      <VirtualTourPopup showPopup={showVirtualTour} closePopup={hideSupportForm} />
      <SupportForm showPopup={showPopup} closeForm={hideSupportForm} entity={entity} />
      <NotificationsModal
        open={isModalOpen}
        handleClose={(page) => {
          setIsModalOpen(false);
          if (page > 0) fetchNotifications({ silent: true });
        }}
        notifications={userNotifications}
        hasUnread={hasUnread}
        accountId={user.uuid}
        setUserNotifications={setUserNotifications}
        setHasUnread={setHasUnread}
      />
      <div className="new-dashboard">
        <div className="nd-header">
          <div className="nd-left-hand-side">
            <div className="nd-logo">
              {setSelectLogo()}
            </div>

            <ProfileLabel user={user} />

            <MiniBalanceLabel locked={locked} />
          </div>
          <div className="nd-right-hand-side">
            <div className="vertical-separator" id="language-selector-box">
              <LanguageSelector selected={selectedLanguage} setSelected={setSelectedLanguage} userUuid={user.uuid} />
            </div>
            <div className="vertical-separator">
              <Link to="/dashboard/profile" className="nd-setting-icon">
                {setSelectSettingsIcon()}
              </Link>
            </div>
            <div className="vertical-separator">
              <div role="button" tabIndex="0" onClick={handleClickOnBellIcon} onKeyDown={handleClickOnBellIcon} style={{ cursor: 'pointer' }}>
                <CustomBadge variant="dot" invisible={!hasUnread}>
                  {setBellIconStyle()}
                </CustomBadge>
              </div>
              <Popover
                id={popoverId}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleCloseNotificationsPopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                style={{ marginTop: '20px' }}
              >
                <NotificationsPopover
                  openModal={isModalOpen}
                  notificationsCount={notificationsCount}
                  setNotificationsCount={setNotificationsCount}
                  handleOpenNotificationsModal={handleOpenNotificationsModal}
                  notifications={userNotifications}
                  hasUnread={hasUnread}
                  accountId={user.uuid}
                  setUserNotifications={setUserNotifications}
                  setHasUnread={setHasUnread}
                />
              </Popover>
            </div>
            <div onClick={logOutUser} onKeyDown={logOutUser} role="button" tabIndex="0">
              {setSelectLogoutIcon()}
            </div>
          </div>
        </div>
        {locked && <OnboardingProgress user={user} />}
        <div className="nd-mobile-header">
          <div className="nd-burger-menu-trigger">
            <BurgerMenu user={user} setUserAuthorized={setUserAuthorized} locked={locked} socket={socket} />
          </div>
          <div className="nd-logo">
            {setSelectLogo()}
          </div>
          <MiniBalanceLabel locked={locked} />
        </div>
        <div className="nd-container">
          <WebSideMenu locked={locked} />
          <div className="nd-content">
            <div className="nd-widgets-area">
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Layout;
