/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import '../../../../styles/Dashboard/Widgets.scss';

const delay = 2500;

export default function MarketingCarousel() {
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);
  const { i18n, t } = useTranslation();

  const appTheme = useSelector((state) => state.theme);
  const isDarkTheme = appTheme === 'dark' ? '-dark' : '';

  const banners = [
    {
      image: '/assets/banners/welcome/welcome',
      text: t('dashboard.main.banners.welcome'),
      forBothThemes: false,
    },
    {
      image: '/assets/banners/zero-withdrawal-fees/zero-fees',
      text: t('dashboard.main.banners.zero'),
      forBothThemes: false,
    },
    {
      image: '/assets/banners/trading-central/trading-central',
      text: t('dashboard.main.banners.tradingCentral'),
      forBothThemes: false,
    },
    {
      image: '/assets/banners/trade-1000/trade-1000',
      text: t('dashboard.main.banners.1000'),
      forBothThemes: true,
    },
    {
      image: '/assets/banners/market-buzz/market-buzz',
      text: t('dashboard.main.banners.marketBuzz'),
      forBothThemes: true,
    },
    {
      image: '/assets/banners/economic-calendar/ec-calendar',
      text: t('dashboard.main.banners.ecCalendar'),
      forBothThemes: true,
    },
    {
      image: '/assets/banners/zar/zar',
      text: t('dashboard.main.banners.zar'),
      forBothThemes: false,
    },
  ];

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () => setIndex((prevIndex) => (prevIndex === banners.length - 1 ? 0 : prevIndex + 1)),
      delay,
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className="marketing-carousel widget-carousel full-widget">
      <div className="slideshow">
        <div
          className="slideshowSlider"
          style={{ transform: i18n.language === 'ar' ? `translate3d(${index * 100}%, 0, 0)` : `translate3d(${-index * 100}%, 0, 0)` }}
        >
          {banners.map((banner, bannerIndex) => (
            <div
              className="slide"
              key={`Banner-${bannerIndex}`}
            >
              <div className="slide-inner">
                {banner.forBothThemes
                  ? <img src={`${banner.image}-${i18n.language}.png`} className="banner-image" alt={bannerIndex} />
                  : <img src={`${banner.image}-${i18n.language}${isDarkTheme}.png`} className="banner-image" alt={bannerIndex} /> }
                <p className="banner-text">{banner.text}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="slideshowDots">
          {banners.map((_, idx) => (
            <input
              type="button"
              key={idx}
              className={`slideshowDot${index === idx ? ' active' : ''}`}
              onClick={() => {
                setIndex(idx);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
