import { notificationAPI } from '../api';
import { errorHelper } from '../helpers';

async function getNotifications({ accountId, limit, offset, sorting }) {
  let notifications;

  try {
    notifications = await notificationAPI.getNotifications({ accountId, limit, offset, sorting });
  } catch (error) {
    throw new errorHelper.CodeError('We encountered an error, please try again later.', error.status);
  }

  return notifications;
}

async function markAllNotificationsAsRead({ accountId }) {
  try {
    return await notificationAPI.markAllNotificationsAsRead({ accountId });
  } catch (error) {
    throw new errorHelper.CodeError(error.message, error.code);
  }
}

async function markManyNotificationsAsRead({ notifications }) {
  try {
    return await notificationAPI.markManyNotificationsAsRead({ notifications });
  } catch (error) {
    throw new errorHelper.CodeError(error.message, error.code);
  }
}

export default {
  getNotifications,
  markAllNotificationsAsRead,
  markManyNotificationsAsRead,
};
