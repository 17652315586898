const videos = [
  {
    id: '1',
    thumbnail: 'https://assets.axiance.com/portal/videos/thumbnails/video1.png',
    url: 'https://assets.axiance.com/portal/videos/videos/video1.mp4',
    language: 'English',
    size: '1920x1080',
    theme: 'Promotional',
  },
  {
    id: '2',
    thumbnail: 'https://assets.axiance.com/portal/videos/thumbnails/video2.png',
    url: 'https://assets.axiance.com/portal/videos/videos/video2.mp4',
    language: 'English',
    size: '1920x1080',
    theme: 'Promotional',
  },
  {
    id: '3',
    thumbnail: 'https://assets.axiance.com/portal/videos/thumbnails/video3.png',
    url: 'https://assets.axiance.com/portal/videos/videos/video3.mp4',
    language: 'English',
    size: '1920x1080',
    theme: 'Promotional',
  },
  {
    id: '4',
    thumbnail: 'https://assets.axiance.com/portal/videos/thumbnails/video4.png',
    url: 'https://assets.axiance.com/portal/videos/videos/video4.mp4',
    language: 'English',
    size: '1920x1080',
    theme: 'Promotional',
  },
  {
    id: '5',
    thumbnail: 'https://assets.axiance.com/portal/videos/thumbnails/video5.png',
    url: 'https://assets.axiance.com/portal/videos/videos/video5.mp4',
    language: 'English',
    size: '1920x1080',
    theme: 'Promotional',
  },
  {
    id: '6',
    thumbnail: 'https://assets.axiance.com/portal/videos/thumbnails/video6.png',
    url: 'https://assets.axiance.com/portal/videos/videos/video6.mp4',
    language: 'English',
    size: '1920x1080',
    theme: 'Promotional',
  },
];
export default videos;
