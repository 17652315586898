import React from 'react';
import Zoom from 'react-reveal/Zoom';
import { MyProfile, MarketAnalysis, MarketingCarousel, YourPartnerLink, CampaignRequest, ClientPresets } from './Widgets';
import DashboardFooter from './DashboardFooter';
import GeneralFeedbackSurvey from '../../GeneralFeedbackSurvey';

function DashboardHome({ user, entity, locked }) {
  return (
    <>
      <Zoom bottom cascade>
        <div className="nd-widget-container">
          <div className="widget-box profile-widget-box">
            <MyProfile user={user} locked={locked} />
            <MarketAnalysis locked={locked} />
          </div>
          <div className="widget-box market-widget-box">
            <MarketingCarousel />
          </div>
          <div className="widget-box marketing-widget-box">
            <CampaignRequest locked={locked} user={user} />
            <ClientPresets locked={locked} />
          </div>
          <div className="widget-box partner-widget-box">
            <YourPartnerLink locked={locked} user={user} entity={entity} />
          </div>
        </div>
      </Zoom>
      <GeneralFeedbackSurvey />
      <DashboardFooter
        entity={entity}
      />
    </>
  );
}

export default DashboardHome;
