import React from 'react';
import '../../../../styles/components/Notifications.scss';
import moment from 'moment';
import { BsCircleFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

export default function NotificationsList({ notifications }) {
  const { t } = useTranslation();

  const setNotificationTitle = ({ code, documentType, documentName, campaignType }) => {
    switch (code) {
      case 'N001': // Account approval
        return t('notifications.n001.title');
      case 'N002': // Document approval
        return t('notifications.n002.title', { documentType, documentName });
      case 'N003': // Document rejection
        return t('notifications.n003.title', { documentType, documentName });
      case 'N004':// IB Deal approval
        return t('notifications.n004.title', { type: campaignType });
      case 'N005': // IB Deal rejection
        return t('notifications.n005.title', { type: campaignType });
      case 'N006': // Withdrawal successful
        return t('notifications.n006.title');
      case 'N007': // Withdrawal rejected
        return t('notifications.n007.title');
      case 'N008': // Client Presets approval
        return t('notifications.n008.title');
      case 'N009': // Client Presets rejected
        return t('notifications.n009.title');
      default:
        return '';
    }
  };

  const setNotificationDescription = ({ code, withdrawalAmount, withdrawalCurrency, tradingCurrency, tradingPlatform }) => {
    switch (code) {
      case 'N001': // Account approval
        return t('notifications.n001.description');
      case 'N003': // Document rejection
        return t('notifications.n003.description');
      case 'N006': // Withdrawal successful
        return t('notifications.n006.description', { amount: withdrawalAmount, currency: withdrawalCurrency });
      case 'N007': // Withdrawal rejected
        return t('notifications.n007.description', { amount: withdrawalAmount, currency: withdrawalCurrency });
      case 'N008': // Client Presets approval
        return t('notifications.n008.description', { tradingPlatform, tradingCurrency });
      case 'N009': // Client Presets rejected
        return t('notifications.n009.description', { tradingPlatform, tradingCurrency });
      default:
        return '';
    }
  };

  return (
    <div className="notifications-container">
      {notifications.map((notification, i) => (
        <div key={i}>
          <div className="notification-row">
            {!notification.read && <BsCircleFill className="unread-icon" />}
            <div className="notification-content-col">
              <p className={notification.read ? 'title-field-read' : 'title-field-unread'}>
                {setNotificationTitle({
                  code: notification.code,
                  documentType: notification.params?.documentType,
                  documentName: notification.params?.documentName,
                  campaignType: notification.params?.campaignType,
                })}
              </p>
              <span className={notification.read ? 'description-field' : 'description-field-unread'}>
                {setNotificationDescription({
                  code: notification.code,
                  documentType: notification.params?.documentType,
                  documentName: notification.params?.documentName,
                  campaignType: notification.params?.campaignType,
                  tradingPlatform: notification.params?.tradingPlatform,
                  tradingCurrency: notification.params?.tradingCurrency,
                })}
              </span>
            </div>
            <div className="notification-date-col">
              <p className="date-field">
                {moment.utc(notification.timestamp).format('DD/MM/YYYY HH:mm')}
              </p>
            </div>
          </div>
          <div className="horizontal-separator" />
        </div>
      ))}
    </div>
  );
}
