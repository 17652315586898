import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import '../../styles/Onboarding/OnBoarding.scss';
import { userController } from '../../controllers';
import clientPresetOptions from '../../utils/clientPresetOptions';
import Button from '../Button';

export default function ClientPresets({ onUploadSuccess }) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'));
  const [pending, setPending] = useState(false);
  const [existingClientPresets, setExistingClientPresets] = useState({});
  const [clientPresets, setClientPresets] = useState({
    tradingPlatform: clientPresetOptions.tradingPlatform[0],
    tradingCurrency: clientPresetOptions.tradingCurrency[0],
    campaignId: '4a7x8p5q',
  });

  useEffect(() => {
    if (user?.clientPresets?.current?.length > 0) {
      setClientPresets(user.clientPresets.current[0]);
      setExistingClientPresets(user.clientPresets.current[0]);
      if (user.clientPresets.current[0].pending) setPending(true);
    } else {
      const registrationCampaign = user.campaignRequests.filter((campaign) => campaign.onRegistration)[0];
      if (registrationCampaign) setClientPresets({ ...clientPresets, campaignId: registrationCampaign.campaignId });
    }
  }, []);

  const submitClientPresets = async (event) => {
    event.preventDefault();
    if (pending) return enqueueSnackbar('You already have a pending request', { variant: 'error' });
    if (existingClientPresets.tradingPlatform === clientPresets.tradingPlatform && existingClientPresets.tradingCurrency === clientPresets.tradingCurrency) {
      return enqueueSnackbar('These presets are already set', { variant: 'success' });
    }
    try {
      setIsLoading(true);
      const presetRequestResponse = await userController.createClientPresetsRequest({ uuid: user.uuid, clientPresetsRequest: clientPresets });
      onUploadSuccess({ requestStatus: presetRequestResponse.requestStatus });
      enqueueSnackbar('Client presets have been updated', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }

    return setIsLoading(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientPresets((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="onboarding-clientPresets kyc-wrap">
      <div className="kyc-wrap-inner">
        <form className="onboard" onSubmit={submitClientPresets}>
          <h2 className="title">{t('clientPresets.text')}</h2>
          <div className="field-splitter">
            <div className="field-group">
              <label htmlFor="tradingPlatform" className="field-label">
                {t('clientPresets.tradingPlatform')}
              </label>
              <select
                className="selector"
                id="tradingPlatform"
                name="tradingPlatform"
                value={clientPresets.tradingPlatform}
                onChange={(e) => handleChange(e)}
              >
                {clientPresetOptions.tradingPlatform.map((tradingPlatform) => (
                  <option key={tradingPlatform} value={tradingPlatform}>{tradingPlatform}</option>
                ))}
              </select>
            </div>
            <div className="field-group">
              <label htmlFor="tradingCurrency" className="field-label">
                {t('clientPresets.tradingCurrency')}
              </label>
              <select
                className="selector"
                id="tradingCurrency"
                name="tradingCurrency"
                value={clientPresets.tradingCurrency}
                onChange={(e) => handleChange(e)}
              >
                {clientPresetOptions.tradingCurrency.map((tradingCurrency) => (
                  <option key={tradingCurrency} value={tradingCurrency}>{tradingCurrency}</option>
                ))}
              </select>
            </div>
          </div>
          <Button secondaryColor loading={isLoading} formButton text={t('submit')} />
        </form>
      </div>
    </div>
  );
}
