import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash-es';
import DateFnsUtils from '@date-io/date-fns';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FaSkype, FaInstagram, FaLinkedinIn, FaFacebookF, FaRegClock, FaRegAddressCard } from 'react-icons/fa';
import { Select, MenuItem, FormControlLabel, Checkbox } from '@material-ui/core';
import weekDays from '../../utils/weekDays';
import '../../styles/components/kyc/socialBox.scss';
import { userController } from '../../controllers';
import Button from '../Button';

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
  // eslint-disable-next-line react/jsx-props-no-spreading
})((props) => <Checkbox color="default" {...props} />);

export default function SocialBox({ user }) {
  const { t } = useTranslation();
  const [submitLoading, setSubmitLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [socialData, setSocialData] = useState({
    facebook: '',
    linkedin: '',
    instagram: '',
    skype: '',
    contactMethod: '',
    availableDays: [],
    availableFrom: new Date(2021, 4, 20, 0, 0, 0),
    availableTo: new Date(2021, 4, 20, 0, 0, 0),
  });

  useEffect(async () => {
    const localUser = await JSON.parse(localStorage.getItem('user'));
    setSocialData(localUser.socialData);
  }, [user]);

  const uploadSocials = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    const sortedSelectedDays = weekDays.filter((value) => socialData.availableDays.includes(value));
    socialData.availableDays = sortedSelectedDays;

    try {
      await userController.uploadSocials(socialData, user.phoneNum);
    } catch (error) {
      setSubmitLoading(false);
      return enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }

    setSubmitLoading(false);
    return enqueueSnackbar('Social data updated!', {
      variant: 'success',
    });
  };

  const handleDaySelection = (e) => {
    const { name, checked } = e.target;
    setSocialData((prevState) => ({
      ...prevState,
      availableDays: checked
        ? [...socialData.availableDays, name]
        : socialData.availableDays.filter((item) => item !== name),
    }));
  };

  const handleSocialChange = (e) => {
    const { name, value } = e.target;
    setSocialData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="social-box">
      <p className="socials-title">{t('documentUpload.social-title')}</p>
      <form
        className="register-form"
        onSubmit={(e) => {
          uploadSocials(e);
        }}
      >
        <div className="social-input-box">
          <div className="field-group">
            <input
              type="text"
              placeholder=" "
              id="facebook"
              name="facebook"
              value={socialData.facebook}
              onChange={handleSocialChange}
              className="field"
            />
            <label htmlFor="facebook" className="input-label">
              <FaFacebookF className="social-icon" />
              Facebook
            </label>
          </div>
        </div>
        <div className="social-input-box">
          <div className="field-group">
            <input
              type="text"
              placeholder=" "
              name="linkedin"
              id="linkedin"
              value={socialData.linkedin}
              onChange={handleSocialChange}
              className="field"
            />
            <label htmlFor="linkedin" className="input-label">
              <FaLinkedinIn className="social-icon" />
              LinkedIn
            </label>
          </div>
        </div>
        <div className="social-input-box">
          <div className="field-group">
            <input
              type="text"
              placeholder=" "
              name="instagram"
              id="instagram"
              value={socialData.instagram}
              onChange={handleSocialChange}
              className="field"
            />
            <label htmlFor="instagram" className="input-label">
              <FaInstagram className="social-icon" />
              Instagram
            </label>
          </div>
        </div>
        <div className="social-input-box">
          <div className="field-group">
            <input
              type="text"
              placeholder=" "
              name="skype"
              id="skype"
              value={socialData.skype}
              onChange={handleSocialChange}
              className="field"
            />
            <label htmlFor="skype" className="input-label">
              <FaSkype className="social-icon" />
              Skype
            </label>
          </div>
        </div>
        <div className="social-input-box">
          <p className="field-top-label">
            <FaRegAddressCard className="social-icon" />
            {t('documentUpload.contactMethod')}
          </p>
          <div className="field-group">
            <Select
              className="menu-item field"
              onChange={handleSocialChange}
              name="contactMethod"
              id="contact-method"
              value={socialData.contactMethod}
            >
              <MenuItem value="none">
                {t('documentUpload.pleaseSelect')}
              </MenuItem>
              <MenuItem
                value="facebook"
                disabled={isEmpty(socialData.facebook)}
              >
                Facebook
              </MenuItem>
              <MenuItem
                value="linkedin"
                disabled={isEmpty(socialData.linkedin)}
              >
                LinkedIn
              </MenuItem>
              <MenuItem
                value="instagram"
                disabled={isEmpty(socialData.instagram)}
              >
                Instagram
              </MenuItem>
              <MenuItem value="skype" disabled={isEmpty(socialData.skype)}>
                Skype
              </MenuItem>
              <MenuItem value="email">{t('login.email')}</MenuItem>
              <MenuItem value="phone">{t('documentUpload.phone')}</MenuItem>
              <MenuItem value="whatsApp">WhatsApp</MenuItem>
              <MenuItem value="telegram">Telegram</MenuItem>
            </Select>
          </div>
        </div>
        <div className="social-input-box">
          <p className="field-top-label">
            <FaRegClock className="social-icon" />
            {t('documentUpload.contactTime')}
          </p>
          <div className="field-group half-group">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <TimePicker
                variant="inline"
                minutesStep={5}
                ampm={false}
                value={socialData.availableFrom}
                onChange={(val) => setSocialData((prevState) => ({
                  ...prevState,
                  availableFrom: val,
                }))}
                className="time"
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <TimePicker
                variant="inline"
                minutesStep={5}
                ampm={false}
                value={socialData.availableTo}
                onChange={(val) => setSocialData((prevState) => ({
                  ...prevState,
                  availableTo: val,
                }))}
                className="time"
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <div className="days-check-container">
          <p className="contact-days">
            {t('documentUpload.contactDays')}
          </p>
          <div className="day-options-container">
            {weekDays.map((item) => (
              <FormControlLabel
                key={item}
                label={item}
                control={(
                  <GreenCheckbox
                    name={item}
                    checked={socialData.availableDays.includes(item) || false}
                    onChange={handleDaySelection}
                  />
                )}
              />
            ))}
          </div>
        </div>
        <div style={{ marginTop: '20px' }}>
          <Button secondaryColor loading={submitLoading} formButton text={t('submit')} />
        </div>
      </form>
    </div>
  );
}
