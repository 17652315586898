import React, { useState, useEffect } from 'react';
import '../../../../styles/components/Notifications.scss';
import { useSnackbar } from 'notistack';
import Pagination from '@material-ui/lab/Pagination';
import ClipLoader from 'react-spinners/ClipLoader';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BiSortAlt2 } from 'react-icons/bi';
import { BellIcon, BellIconDark } from '../../../../media/images/icons';
import { notificationController } from '../../../../controllers';
import NotificationsList from './NotificationsList';
import { Popup } from '../../..';
import { notificationsLimit } from '../../../../utils/notificationConsts';

const NotificationsModal = ({
  open, handleClose, notifications, hasUnread, accountId, setUserNotifications, setHasUnread,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const itemsPerPage = 5;
  const [page, setPage] = useState(1);
  const [defaultPage, setDefaultPage] = useState(1);
  const [notificationsCount, setNotificationsCount] = useState();
  const [loading, setIsLoading] = useState(false);
  const [pageHasUnread, setPageHasUnread] = useState(false);
  const [notificationsLoading, setNotificationsLoading] = useState(false);
  const [sorted, setSorted] = useState(true);
  const { t } = useTranslation();
  const clipLoaderColor = '#50B848';
  const getTheme = useSelector((state) => state.theme);

  useEffect(async () => {
    if (open) {
      setDefaultPage(1);
      setPage(1);
      setSorted(true);
    }
  }, [open]);

  const setBellIconStyle = () => {
    if (getTheme === 'dark') {
      return <img src={BellIconDark} title="notifications" alt="bell-icon" id="bell-icon" className="modal-bell-icon" />;
    }
    return (
      <img src={BellIcon} title="notifications" alt="bell-icon" id="bell-icon" className="modal-bell-icon" />);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  async function fetchNotifications({ showSpinner }) {
    try {
      if (showSpinner) setNotificationsLoading(true);
      const notificationsFetch = await notificationController.getNotifications({ accountId, limit: notificationsLimit, offset: (page - 1) * notificationsLimit, sorting: sorted ? 'desc' : 'asc' });
      setNotificationsCount(notificationsFetch.notificationsCount);
      setUserNotifications(notificationsFetch.notifications);
      setHasUnread(notificationsFetch.hasUnread);
      setPageHasUnread(notificationsFetch.pageHasUnread);
      if (showSpinner) setNotificationsLoading(false);
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  }

  useEffect(async () => {
    if (open) {
      try {
        fetchNotifications({ showSpinner: true });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  }, [open, page, sorted]);

  useEffect(async () => {
    if (open && page > 0) {
      try {
        fetchNotifications({ showSpinner: true });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  }, [sorted]);

  const handleMarkAllAsRead = async () => {
    if (notifications.length && hasUnread) {
      try {
        setIsLoading(true);
        await notificationController.markAllNotificationsAsRead({ accountId });
        setHasUnread(false);
        fetchNotifications({ showSpinner: false });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
      setIsLoading(false);
    }
  };

  async function readNotifications() {
    try {
      if (!pageHasUnread) return;
      await notificationController.markManyNotificationsAsRead({ notifications });
      fetchNotifications({ showSpinner: false });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  useEffect(() => {
    if (open && pageHasUnread) setTimeout(readNotifications, 3000);
  }, [open, pageHasUnread, page]);

  return (
    <div className="notifications-modal">
      <Popup showPopup={open} closePopup={() => handleClose(page)}>
        <div className="panel" style={{ paddingTop: '15px' }}>
          <div className="modal-title-container">
            {setBellIconStyle()}
            <span className="modal-title">
              {t('dashboard.notifications.modalTitle')}
            </span>
            <div className="sorting">
              <span className="label">{t('dashboard.notifications.date')}</span>
              <BiSortAlt2 onClick={() => setSorted(!sorted)} className="sort-icon" />
            </div>
          </div>
          {hasUnread && (
            loading
              ? (
                <div className="clip-loader-div">
                  <ClipLoader color={clipLoaderColor} css="display: flex" size={15} />
                </div>
              )
              : (
                <div role="button" tabIndex={0} onClick={handleMarkAllAsRead} onKeyDown={handleMarkAllAsRead} className="mark-read-text">
                  <span>{t('dashboard.notifications.markAsRead')}</span>
                </div>
              )
          )}
          {notificationsLoading ? (
            <div className="notifications-loader-div">
              <ClipLoader color={clipLoaderColor} size={30} />
            </div>
          ) : (

            <NotificationsList notifications={notifications} />
          )}
          <div className="pagination-container">
            <Pagination
              count={Math.ceil(notificationsCount / itemsPerPage)}
              page={page}
              onChange={handleChange}
              defaultPage={defaultPage}
            />
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default NotificationsModal;
