import axios from 'axios';
import { errorHelper } from '../helpers';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const instance = axios.create({
  baseURL: backendURL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

async function getNotifications({ accountId, limit, offset, sorting }) {
  try {
    const notifications = await instance.get('/notifications', { params: { accountId, limit, offset, sorting } });
    return notifications.data;
  } catch (error) {
    throw new errorHelper.CodeError('We encountered an error, please try again later.', error.status);
  }
}

async function markAllNotificationsAsRead({ accountId }) {
  try {
    return await instance.put('/notifications/read/all', JSON.stringify({ accountId }));
  } catch (error) {
    throw new errorHelper.CodeError(error.response.data, error.response.status);
  }
}

async function markManyNotificationsAsRead({ notifications }) {
  try {
    return await instance.put('/notifications/read/many', JSON.stringify({ notifications }));
  } catch (error) {
    throw new errorHelper.CodeError(error.response.data, error.response.status);
  }
}

export default {
  getNotifications,
  markAllNotificationsAsRead,
  markManyNotificationsAsRead,
};
